<template>
  <VueFinalModal
    class="createNetMessage__modal"
    content-class="createNetMessage__content"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <div class="createNetMessage__title">Введите имя сети</div>
    <div class="createNetMessage__subtitle">
      <span class="subtitle">
        Перед созданием точек продаж, необходимо задать "Название Сети". Это
        <span class="r-link" @click="openFaq">доменное имя</span>
        которое нельзя будет изменить.<br />
      </span>
    </div>
    <p class="domain">
      https://qbeek.io/<span
        :class="[
          'domain__name',
          { 'is-invalid': v$?.merchantSlugName?.$error },
        ]"
        class="domain__name"
        >{{ network.merchantSlugName || "доменное имя" }}</span
      >/subdomain
    </p>
    <div class="createNetMessage__input">
      <ErrorCheck
        :is-error="v$?.merchantSlugName?.$error"
        :error-message="v$?.merchantSlugName?.$errors[0]?.$message"
      >
        <network-name-item
          v-model="network.merchantSlugName"
          placeholder="Используйте только латинские прописные буквы"
          :disabled="isLoading"
          :class="{ 'is-invalid': v$?.merchantSlugName?.$error }"
          @update:model-value="slugName"
        />
      </ErrorCheck>
    </div>
    <div class="createNetMessage__buttons">
      <action-button w100p :spin="isLoading" @click="emit('close')">
        Отмена
      </action-button>
      <action-button w100p action :spin="isLoading" @click="saveNetworkName">
        Сохранить
      </action-button>
    </div>
    <div class="close-icon">
      <v-icon name="close" class="modal__close" @click="emit('close')" />
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";
import { ref, reactive, onMounted, onUnmounted, computed } from "vue";
import slugify from "slugify";
import NetworkNameItem from "@/components/UI/inputs/TextInput.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { checkNameUniq } from "@/services/merchant.service";
// import i18n from "@/i18n";
import { updateMerchantSlugName } from "@/services/merchant.service";
import i18n from "@/i18n";

const { withAsync } = helpers;
defineProps({
  title: {
    type: String,
    default: "Title",
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits({
  confirm: null,
  close: null,
  open: null,
});
// const { t } = i18n.global;
const toast = useToast();
const router = useRouter();
const store = useStore();
const isLoading = ref(false);
const network = reactive({
  merchantSlugName: "",
});
// const merchantSlugName = ref();
const notEmptyMessage = computed(() => "Имя сети не может быть пустым");
const nameUniq = computed(() => "Имя сети занято");

const rules = computed(() => ({
  merchantSlugName: {
    required: helpers.withMessage(notEmptyMessage, required),
    checkNameUniq: helpers.withMessage(nameUniq, withAsync(checkNameUniq)),
    // async checkNameUniq(value) {
    //   if (value === "") return true;
    //   const response = await checkNameUniq(value);
    //   return !response;
    // },
  },
}));
const v$ = useVuelidate(rules, network, { $rewardEarly: true });

async function saveNetworkName() {
  const resultValidate = await v$.value.$validate();

  if (!resultValidate) return;

  try {
    isLoading.value = true;
    const result = await updateMerchantSlugName(network.merchantSlugName);

    // await sleep(1);
    isLoading.value = false;
    if (result) {
      store.commit("set_merchantSlugName", network.merchantSlugName);
      toast.success("Имя сети сохранено!");
      emit("confirm");
    } else {
      toast.warning("Данное имя занято! Попробуйте еще раз!", {
        timeout: 5000,
      });
    }
  } catch (error) {
    console.log(error);
    return;
  }
}
function sleep(seconds) {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}
function slugName() {
  const netName = slugify(network.merchantSlugName, {
    replacement: "_",
    lower: true,
    trim: true,
    remove: /[`'’",.]/g,
  });
  network.merchantSlugName = netName;
}
function closeModal() {
  v$.value.$reset();
  // merchantSlugName.value = "";
  emit("close");
}
function openFaq() {
  /* 
  <router-link
          :to=""
          ></router-link
        >
  
  */
 emit("close");
  router.push({
            name: 'faq:article',
            params: { index: 3, subindex: 0, locale: i18n.global.locale },
          });
}
onMounted(async () => {
  v$.value.$reset();
  network.merchantSlugName = store.getters.getMerchantSlugName;
});
onUnmounted(() => {
  v$.value.$reset();
});
// // TODO: Tranlate errors and  fields i18n helpers
</script>

<style lang="scss">
.createNetMessage {
  &__modal {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  &__content {
    position: relative;
    width: 500px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #595a5b;
    padding: 35px 40px;
    border-radius: 6px;
    background: #fff;
  }
  &__title {
    font-size: 28px;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 10px;
  }
  &__subtitle {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;
    .subtitle {
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      color: #9b9b9b;
    }
    .r-link {
      color: $primary-color;
      cursor: pointer;
    }
  }
  &__input {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #9b9b9b;
    margin-bottom: 24px;
  }
  &__buttons {
    @include flex-row(10px);
  }
}
.close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: #9b9b9b;
  font-size: 20px;
  line-height: 24px;
}

/* span {
  color: red;
  font-size: 12px;
} */
.domain {
  background-color: #10b3c710;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: center;
  padding: 0px 16px;
  margin-bottom: 12px;
  color: #1f1f1f;
  word-break: break-all;
  &__name {
    color: #10b3c7;
    &.is-invalid {
      color: #ff0000;
      background-color: #ff000010;
    }
  }
}
</style>
