export default {
  "login.errors.wrongLoginOrPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный логин или пароль"])};fn.source="Неверный логин или пароль";return fn;})(),
  "profile": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])};fn.source="Профиль";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка профиля"])};fn.source="Настройка профиля";return fn;})(),
    "firstname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])};fn.source="Имя";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иван"])};fn.source="Иван";return fn;})()
    },
    "lastname": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])};fn.source="Фамилия";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванов"])};fn.source="Иванов";return fn;})()
    },
    "patronymic": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчество"])};fn.source="Отчество";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иванович"])};fn.source="Иванович";return fn;})()
    },
    "email": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])};fn.source="Электронная почта";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ivan.ivanov", "@", "gmail.com"])};fn.source="ivan.ivanov{'@'}gmail.com";return fn;})()
    },
    "phone": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])};fn.source="Телефон";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])};fn.source="Телефон";return fn;})()
    },
    "buttons": {
      "changePasswords": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить пароль"])};fn.source="Сменить пароль";return fn;})(),
      "bankDetails": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские реквизиты"])};fn.source="Банковские реквизиты";return fn;})(),
      "tipCardNumber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер карты для чаевых"])};fn.source="Номер карты для чаевых";return fn;})(),
      "saveUserInfo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить данные пользователя"])};fn.source="Обновить данные пользователя";return fn;})()
    },
    "passwordChange": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])};fn.source="Изменить пароль";return fn;})(),
      "form": {
        "oldPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий пароль"])};fn.source="Текущий пароль";return fn;})(),
        "newPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])};fn.source="Новый пароль";return fn;})(),
        "confirmNewPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите новый пароль"])};fn.source="Подтвердите новый пароль";return fn;})()
      },
      "buttons": {
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])};fn.source="Отмена";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])};fn.source="Изменить пароль";return fn;})()
      },
      "errors": {
        "require": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не может быть пустым"])};fn.source="Пароль не может быть пустым";return fn;})(),
        "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пароль должен содержать не менее ", _interpolate(_named("n")), " символов"])};fn.source="Пароль должен содержать не менее {n} символов";return fn;})(),
        "samePassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли должны совпадать"])};fn.source="Пароли должны совпадать";return fn;})(),
        "hasDigitMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну цифру"])};fn.source="Пароль должен содержать хотя бы одну цифру";return fn;})(),
        "hasUppercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну прописную букву"])};fn.source="Пароль должен содержать хотя бы одну прописную букву";return fn;})(),
        "hasLowercaseMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы одну строчную букву"])};fn.source="Пароль должен содержать хотя бы одну строчную букву";return fn;})(),
        "hasSpecialSymbolsMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль должен содержать хотя бы один специальный символ"])};fn.source="Пароль должен содержать хотя бы один специальный символ";return fn;})(),
        "tooManyRequests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Слишком много запросов"])};fn.source="Слишком много запросов";return fn;})(),
        "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный текущий пароль"])};fn.source="Неверный текущий пароль";return fn;})()
      }
    }
  },
  "datapicker": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])};fn.source="Отмена";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])};fn.source="Сохранить";return fn;})(),
    "timeplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать время"])};fn.source="Выбрать время";return fn;})(),
    "dateplaceholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать дату"])};fn.source="Выбрать дату";return fn;})()
  },
  "tasks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])};fn.source="Добавить";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки задачи"])};fn.source="Настройки задачи";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка задачи на определенное время"])};fn.source="Настройка задачи на определенное время";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])};fn.source="Дата и время";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время задачи"])};fn.source="Здесь вы можете настроить дату и время задачи";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название задачи не может быть пустым"])};fn.source="Название задачи не может быть пустым";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название задачи не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Название задачи не может быть меньше {n} символов";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание задачи не может быть пустым"])};fn.source="Описание задачи не может быть пустым";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание задачи не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Описание задачи не может быть меньше {n} символов";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])};fn.source="Выберите хотя бы одну точку";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])};fn.source="Дата не может быть после конечной";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])};fn.source="Дата не может быть до начальной";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование задачи"])};fn.source="Редактирование задачи";return fn;})()
    }
  },
  "news": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])};fn.source="Добавить";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки новости"])};fn.source="Настройки новости";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка новости на определенное время"])};fn.source="Настройка новости на определенное время";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])};fn.source="Дата и время";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время новости"])};fn.source="Здесь вы можете настроить дату и время новости";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название новости не может быть пустым"])};fn.source="Название новости не может быть пустым";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название новости не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Название новости не может быть меньше {n} символов";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание новости не может быть пустым"])};fn.source="Описание новости не может быть пустым";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание новости не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Описание новости не может быть меньше {n} символов";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])};fn.source="Выберите хотя бы одну точку";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])};fn.source="Дата не может быть после конечной";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])};fn.source="Дата не может быть до начальной";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование новости"])};fn.source="Редактирование новости";return fn;})()
    }
  },
  "feedbacks": {
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])};fn.source="Добавить";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки обратной связи"])};fn.source="Настройки обратной связи";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка обратной связи на определенное время"])};fn.source="Настройка обратной связи на определенное время";return fn;})(),
      "time": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время"])};fn.source="Дата и время";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить дату и время обратной связи"])};fn.source="Здесь вы можете настроить дату и время обратной связи";return fn;})()
      },
      "errors": {
        "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название обратной связи не может быть пустым"])};fn.source="Название обратной связи не может быть пустым";return fn;})(),
        "nameMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Название обратной связи не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Название обратной связи не может быть меньше {n} символов";return fn;})(),
        "descriptionNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание обратной связи не может быть пустым"])};fn.source="Описание обратной связи не может быть пустым";return fn;})(),
        "descriptionMinLength": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Описание обратной связи не может быть меньше ", _interpolate(_named("n")), " символов"])};fn.source="Описание обратной связи не может быть меньше {n} символов";return fn;})(),
        "pointsNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите хотя бы одну точку"])};fn.source="Выберите хотя бы одну точку";return fn;})(),
        "dateNotBefore": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть после конечной"])};fn.source="Дата не может быть после конечной";return fn;})(),
        "dateNotAfter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата не может быть до начальной"])};fn.source="Дата не может быть до начальной";return fn;})()
      }
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактирование обратной связи"])};fn.source="Редактирование обратной связи";return fn;})()
    }
  },
  "multiselect": {
    "current": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["Выбрано 0 точек"]), _normalize(["Выбрана ", _interpolate(_named("n")), " точка"]), _normalize(["Выбрано ", _interpolate(_named("n")), " точки"]), _normalize(["Выбрано ", _interpolate(_named("n")), " точек"])])};fn.source="Выбрано 0 точек | Выбрана {n} точка |  Выбрано {n} точки  |  Выбрано {n} точек";return fn;})(),
    "allpoints": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбраны все точки"])};fn.source="Выбраны все точки";return fn;})()
  },
  "phone": {
    "countrySelector": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код страны"])};fn.source="Код страны";return fn;})(),
      "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])};fn.source="Выберите страну";return fn;})()
    },
    "phoneInput": {
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])};fn.source="Номер телефона";return fn;})(),
      "example": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример:"])};fn.source="Пример:";return fn;})()
    }
  },
  "button": {
    "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])};fn.source="Отмена";return fn;})(),
    "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])};fn.source="Сохранить";return fn;})(),
    "update": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])};fn.source="Сохранить";return fn;})()
  },
  "toast": {
    "successSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Настройки ", _interpolate(_named("target")), " сохранены!"])};fn.source="Настройки {target} сохранены!";return fn;})(),
    "errorSaveSettings": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ошибка сохранения настроек ", _interpolate(_named("target")), "!"])};fn.source="Ошибка сохранения настроек {target}!";return fn;})(),
    "deleted": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("target")), " удален!"])};fn.source="{target} удален!";return fn;})(),
    "word": {
      "product": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продукт"])};fn.source="Продукт";return fn;})()
    }
  },
  "agents": {
    "nav_buttons": {
      "agents_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список агентов"])};fn.source="Список агентов";return fn;})()
    }
  },
  "merchants": {
    "nav_buttons": {
      "merchants_list": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список клиентов"])};fn.source="Список клиентов";return fn;})()
    }
  },
  "crm": {
    "nav_buttons": {
      "clients": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])};fn.source="Клиенты";return fn;})(),
      "push": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылка"])};fn.source="Рассылка";return fn;})(),
      "feedbacks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзывы"])};fn.source="Отзывы";return fn;})(),
      "templates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])};fn.source="Шаблоны";return fn;})(),
      "triggers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматическая рассылка"])};fn.source="Автоматическая рассылка";return fn;})(),
      "orders": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])};fn.source="Заказы";return fn;})(),
      "kitchen": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кухня"])};fn.source="Кухня";return fn;})()
    },
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])};fn.source="Обратная связь";return fn;})(),
    "notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])};fn.source="Уведомления";return fn;})()
  },
  "staff": {
    "buttonTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка персонала"])};fn.source="Настройка персонала";return fn;})(),
    "buttonCalls": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вызов персонала"])};fn.source="Вызов персонала";return fn;})(),
    "buttonTasks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задачи персонала"])};fn.source="Задачи персонала";return fn;})(),
    "buttonNews": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новостная лента"])};fn.source="Новостная лента";return fn;})(),
    "buttonNotifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылка"])};fn.source="Рассылка";return fn;})(),
    "buttonFeedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])};fn.source="Обратная связь";return fn;})(),
    "newButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])};fn.source="Добавить";return fn;})(),
    "new": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый сотрудник"])};fn.source="Новый сотрудник";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте нового сотрудника"])};fn.source="Добавьте нового сотрудника";return fn;})()
    },
    "edit": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать сотрудника"])};fn.source="Редактировать сотрудника";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внести изменения"])};fn.source="Внести изменения";return fn;})()
    },
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный логин"])};fn.source="Неправильный логин";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не найден"])};fn.source="Email не найден";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не должен быть пустым"])};fn.source="Email не должен быть пустым";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат email"])};fn.source="Неверный формат email";return fn;})(),
      "surnameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия не должна быть пустой"])};fn.source="Фамилия не должна быть пустой";return fn;})(),
      "nameNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя не должно быть пустым"])};fn.source="Имя не должно быть пустым";return fn;})(),
      "minLength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле должно содержать не менее 3 символов"])};fn.source="Поле должно содержать не менее 3 символов";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])};fn.source="Неправильный пароль";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])};fn.source="Неправильный пароль";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен быть пустым"])};fn.source="Пароль не должен быть пустым";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не можем найти пользователя с этим email адресом."])};fn.source="Мы не можем найти пользователя с этим email адресом.";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона не может быть пустым"])};fn.source="Номер телефона не может быть пустым";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверьте длину телефона"])};fn.source="Проверьте длину телефона";return fn;})()
      }
    }
  },
  "pselection": {
    "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор программы лояльности"])};fn.source="Выбор программы лояльности";return fn;})(),
    "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите и настройте вашу программу лояльности"])};fn.source="Выберите и настройте вашу программу лояльности";return fn;})(),
    "radioButton": {
      "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])};fn.source="Скидка";return fn;})(),
      "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус"])};fn.source="Бонус";return fn;})()
    },
    "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])};fn.source="Далее";return fn;})(),
    "discount": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дисконтная программа лояльности"])};fn.source="Дисконтная программа лояльности";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка вашей прямой скидки клиенту"])};fn.source="Настройка вашей прямой скидки клиенту";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уровней"])};fn.source="Настройка уровней";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите необходимое количество уровней, их сумму и процент скидки"])};fn.source="Выберите необходимое количество уровней, их сумму и процент скидки";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название уровня"])};fn.source="Название уровня";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])};fn.source="Сумма от";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма до"])};fn.source="Сумма до";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка, %"])};fn.source="Скидка, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить уровень"])};fn.source="Добавить уровень";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])};fn.source="Скидка";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])};fn.source="Владелец карты";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilDiscount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До скидки"])};fn.source="До скидки";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])};fn.source="Имя Фамилия";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки скидок сохранены!"])};fn.source="Настройки скидок сохранены!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настроек скидок!"])};fn.source="Ошибка сохранения настроек скидок!";return fn;})()
      }
    },
    "bonus": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонусная система лояльности"])};fn.source="Бонусная система лояльности";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка для вашей бонусной программы"])};fn.source="Настройка для вашей бонусной программы";return fn;})(),
      "levels": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка уровней"])};fn.source="Настройка уровней";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите необходимое количество уровней, их сумму и процент скидки"])};fn.source="Выберите необходимое количество уровней, их сумму и процент скидки";return fn;})(),
        "row": {
          "level": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название уровня"])};fn.source="Название уровня";return fn;})(),
          "from": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма от"])};fn.source="Сумма от";return fn;})(),
          "to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма до"])};fn.source="Сумма до";return fn;})(),
          "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бонус, %"])};fn.source="Бонус, %";return fn;})()
        },
        "addButton": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить уровень"])};fn.source="Добавить уровень";return fn;})()
      },
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])};fn.source="Скидка";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])};fn.source="Баланс";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])};fn.source="Владелец карты";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])};fn.source="До бонуса";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])};fn.source="Имя Фамилия";return fn;})()
      },
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки бонусов сохранены!"])};fn.source="Настройки бонусов сохранены!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настроек бонусов!"])};fn.source="Ошибка сохранения настроек бонусов!";return fn;})()
      }
    },
    "coupon": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купонная программа лояльности"])};fn.source="Купонная программа лояльности";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроивать ваш купон и количество действий для его получения, повторяемый он или нет"])};fn.source="Здесь вы можете настроивать ваш купон и количество действий для его получения, повторяемый он или нет";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])};fn.source="Скидка";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])};fn.source="Баланс";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])};fn.source="Владелец карты";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])};fn.source="До бонуса";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])};fn.source="Имя Фамилия";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coupon"])};fn.source="coupon";return fn;})(),
        "accumulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["накопление"])};fn.source="накопление";return fn;})(),
        "congratulation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["поздравление"])};fn.source="поздравление";return fn;})()
      }
    },
    "clubcard": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клубная карта"])};fn.source="Клубная карта";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр клубной карты"])};fn.source="Предпросмотр клубной карты";return fn;})(),
      "walletCard": {
        "discount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])};fn.source="Скидка";return fn;})(),
        "bonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])};fn.source="Баланс";return fn;})(),
        "cardOwner": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Владелец карты"])};fn.source="Владелец карты";return fn;})(),
        "status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])};fn.source="Статус";return fn;})(),
        "untilBonus": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До бонуса"])};fn.source="До бонуса";return fn;})(),
        "FIO": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Фамилия"])};fn.source="Имя Фамилия";return fn;})(),
        "coupon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["купон"])};fn.source="купон";return fn;})()
      }
    }
  },
  "vclient": {
    "submenu": {
      "brand": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бренд"])};fn.source="Бренд";return fn;})(),
      "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])};fn.source="Wallet";return fn;})(),
      "qr-catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})()
    },
    "qrCatalog": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте своё Онлайн-меню"])};fn.source="Настройте своё Онлайн-меню";return fn;})(),
      "colors": {
        "primary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста"])};fn.source="Цвет текста";return fn;})(),
        "secondary": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет заголовка"])};fn.source="Цвет заголовка";return fn;})(),
        "relief-1": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет фона"])};fn.source="Цвет фона";return fn;})(),
        "relief-2": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет категории"])};fn.source="Цвет категории";return fn;})(),
        "relief-7": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста цены"])};fn.source="Цвет текста цены";return fn;})(),
        "relief-6": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет фона цены"])};fn.source="Цвет фона цены";return fn;})()
      },
      "logoTitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для каталога"])};fn.source="Логотип для каталога";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип текст для каталога"])};fn.source="Логотип текст для каталога";return fn;})(),
      "downloadLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка QR Code"])};fn.source="Загрузка QR Code";return fn;})(),
      "previewLink": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр"])};fn.source="Предпросмотр";return fn;})(),
      "previewLinkHint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все изменения будут доступны после нажатия на кнопку \"Опубликовать\""])};fn.source="Все изменения будут доступны после нажатия на кнопку \"Опубликовать\"";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])};fn.source="Сохранить";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню настройки сохранены!"])};fn.source="Онлайн-меню настройки сохранены!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения настройки Онлайн-меню!"])};fn.source="Ошибка сохранения настройки Онлайн-меню!";return fn;})()
      }
    },
    "wallet": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet для карт"])};fn.source="Wallet для карт";return fn;})(),
      "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка Wallet для карт"])};fn.source="Настройка Wallet для карт";return fn;})(),
      "cardColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет карты"])};fn.source="Цвет карты";return fn;})(),
      "textColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет текста"])};fn.source="Цвет текста";return fn;})(),
      "titleColor": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет заголовка"])};fn.source="Цвет заголовка";return fn;})(),
      "logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип для Wallet"])};fn.source="Логотип для Wallet";return fn;})(),
      "logoText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст логотипа для Wallet"])};fn.source="Текст логотипа для Wallet";return fn;})(),
      "centralImage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центральное изображение для Wallet"])};fn.source="Центральное изображение для Wallet";return fn;})(),
      "button": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])};fn.source="Сохранить";return fn;})(),
      "preview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предпросмотр"])};fn.source="Предпросмотр";return fn;})(),
      "toast": {
        "success": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки Wallet сохранены!"])};fn.source="Настройки Wallet сохранены!";return fn;})(),
        "error": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при сохранении настроек Wallet!"])};fn.source="Ошибка при сохранении настроек Wallet!";return fn;})()
      }
    }
  },
  "leftSideMenu": {
    "agents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агенты"])};fn.source="Агенты";return fn;})(),
    "averageСheck": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средний чек"])};fn.source="Средний чек";return fn;})(),
    "catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
    "clientData": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные клиента"])};fn.source="Данные клиента";return fn;})(),
    "crm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRM"])};fn.source="CRM";return fn;})(),
    "dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])};fn.source="Статистика";return fn;})(),
    "faq": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])};fn.source="FAQ";return fn;})(),
    "feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])};fn.source="Обратная связь";return fn;})(),
    "for-client": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для клиентов"])};fn.source="Для клиентов";return fn;})(),
    "for-staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для персонала"])};fn.source="Для персонала";return fn;})(),
    "history": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])};fn.source="История";return fn;})(),
    "integrations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграции"])};fn.source="Интеграции";return fn;})(),
    "levelRetention": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удержание клиентов"])};fn.source="Удержание клиентов";return fn;})(),
    "levelSatisfaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удовлетворённость клиентов"])};fn.source="Удовлетворённость клиентов";return fn;})(),
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])};fn.source="Главная";return fn;})(),
    "merchants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])};fn.source="Клиенты";return fn;})(),
    "pointsOfSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])};fn.source="Точки продаж";return fn;})(),
    "profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])};fn.source="Профиль";return fn;})(),
    "pselection": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программа лояльности"])};fn.source="Программа лояльности";return fn;})(),
    "staff": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Персонал"])};fn.source="Персонал";return fn;})(),
    "statistic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика"])};fn.source="Статистика";return fn;})(),
    "support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержка"])};fn.source="Поддержка";return fn;})(),
    "topSale": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ продаж"])};fn.source="Топ продаж";return fn;})(),
    "transaction": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])};fn.source="Транзакции";return fn;})(),
    "wallet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Карта Wallet"])};fn.source="Карта Wallet";return fn;})(),
    "departments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отделы"])};fn.source="Отделы";return fn;})()
  },
  "application": {
    "imageForBothTheme": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the downloaded image for both themes in the app"])};fn.source="Use the downloaded image for both themes in the app";return fn;})()
  },
  "locale": {
    "uk": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укр"])};fn.source="Укр";return fn;})(),
    "ru": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рус"])};fn.source="Рус";return fn;})(),
    "en": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eng"])};fn.source="Eng";return fn;})()
  },
  "login": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])};fn.source="Добро пожаловать";return fn;})(),
    "forgotQuery": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])};fn.source="Забыли пароль?";return fn;})(),
      "sub": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите e-mail, указанный при регистрации. Мы отправим вам ссылку для сброса пароля."])};fn.source="Введите e-mail, указанный при регистрации. Мы отправим вам ссылку для сброса пароля.";return fn;})(),
      "messageSuccessReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы отправили ссылку для восстановления пароля на указанный Email."])};fn.source="Мы отправили ссылку для восстановления пароля на указанный Email.";return fn;})()
    },
    "pleaseSignIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, войдите в ваш аккаунт"])};fn.source="Пожалуйста, войдите в ваш аккаунт";return fn;})(),
    "buttonLogin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])};fn.source="Войти";return fn;})(),
    "buttonReset": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить пароль"])};fn.source="Сбросить пароль";return fn;})(),
    "rememberMe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запомнить меня"])};fn.source="Запомнить меня";return fn;})(),
    "forgotPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])};fn.source="Забыли пароль?";return fn;})(),
    "errors": {
      "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])};fn.source="Неправильный пароль";return fn;})(),
      "emailNotFound": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта не найдена"])};fn.source="Электронная почта не найдена";return fn;})(),
      "emailNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email не должен быть пустым"])};fn.source="Email не должен быть пустым";return fn;})(),
      "emailNotValid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта недействительна"])};fn.source="Электронная почта недействительна";return fn;})(),
      "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный пароль"])};fn.source="Неправильный пароль";return fn;})(),
      "wrongPassword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный пароль"])};fn.source="Неверный пароль";return fn;})(),
      "passwordNotEmpty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль не должен быть пустым"])};fn.source="Пароль не должен быть пустым";return fn;})(),
      "resetNotEmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы не можем найти пользователя с этим электронным адресом."])};fn.source="Мы не можем найти пользователя с этим электронным адресом.";return fn;})()
    }
  },
  "menu": {
    "main": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основна сторінка"])};fn.source="Основна сторінка";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логін"])};fn.source="Логін";return fn;})(),
    "registration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реєстрація"])};fn.source="Реєстрація";return fn;})()
  },
  "form": {
    "email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])};fn.source="Электронная почта";return fn;})(),
    "password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])};fn.source="Пароль";return fn;})(),
    "login": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин"])};fn.source="Логин";return fn;})(),
    "register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регестрация"])};fn.source="Регестрация";return fn;})()
  },
  "main": {
    "welcome": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ", хотите начать настройку?"])};fn.source="{name}, хотите начать настройку?";return fn;})(),
    "signout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])};fn.source="Выйти";return fn;})(),
    "new_message": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["немає повідомлень"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлення"]), _normalize([_interpolate(_named("count")), " повідомлень"])])};fn.source="немає повідомлень | {count} повідомлення  | {count} повідомлення | {count} повідомлень";return fn;})()
  },
  "nav": {
    "account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личный кабинет"])};fn.source="Личный кабинет";return fn;})(),
    "profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])};fn.source="Профиль";return fn;})(),
    "loyalty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Программа лояльности"])};fn.source="Программа лояльности";return fn;})(),
    "bank-links": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковские ссылки"])};fn.source="Банковские ссылки";return fn;})()
  },
  "points": {
    "workTime": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы"])};fn.source="Режим работы";return fn;})()
    },
    "departments": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отделы"])};fn.source="Отделы";return fn;})()
    },
    "titlenameNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая точка продаж"])};fn.source="Новая точка продаж";return fn;})(),
    "subtitleNew": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаг 1. Добавьте новую точку продаж. После добавления информации нажмите &quot;Продолжить&quot;."])};fn.source="Шаг 1. Добавьте новую точку продаж. После добавления информации нажмите &quot;Продолжить&quot;.";return fn;})(),
    "titlenameEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать точку"])};fn.source="Редактировать точку";return fn;})(),
    "subtitleEdit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отредактируйте точку, чтобы клиенты могли связаться с вами"])};fn.source="Отредактируйте точку, чтобы клиенты могли связаться с вами";return fn;})(),
    "phone": {
      "delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить номер телефона"])};fn.source="Удалить номер телефона";return fn;})(),
      "addPhone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить телефон"])};fn.source="Добавить телефон";return fn;})()
    },
    "btnNewPoint": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить точку продаж"])};fn.source="Добавить точку продаж";return fn;})(),
    "table": {
      "header": {
        "point": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])};fn.source="Точки продаж";return fn;})(),
        "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])};fn.source="Адрес";return fn;})(),
        "qrQatalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Онлайн-меню"])};fn.source="Онлайн-меню";return fn;})(),
        "phone": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕЛЕФОН"])};fn.source="ТЕЛЕФОН";return fn;})(),
        "viber": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIBER"])};fn.source="VIBER";return fn;})(),
        "whatsapp": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHATSAPP"])};fn.source="WHATSAPP";return fn;})(),
        "telegram": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TELEGRAM"])};fn.source="TELEGRAM";return fn;})(),
        "action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДЕЙСТВИЯ"])};fn.source="ДЕЙСТВИЯ";return fn;})()
      },
      "body": {
        "emptyMessage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы еще не добавили ни одной точки продаж"])};fn.source="Вы еще не добавили ни одной точки продаж";return fn;})()
      },
      "delete": {
        "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить контакт"])};fn.source="Удалить контакт";return fn;})(),
        "subtitle": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить контакт? Все данные будут удалены"])};fn.source="Вы уверены, что хотите удалить контакт? Все данные будут удалены";return fn;})(),
        "cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])};fn.source="Отменить";return fn;})(),
        "ok": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])};fn.source="Удалить";return fn;})()
      }
    },
    "address": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])};fn.source="Адрес";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните вводить свой адрес"])};fn.source="Начните вводить свой адрес";return fn;})()
    },
    "title": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])};fn.source="Название";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите название"])};fn.source="Введите название";return fn;})()
    },
    "subdomain": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя"])};fn.source="Доменное имя";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите доменное имя"])};fn.source="Введите доменное имя";return fn;})()
    },
    "errors": {
      "address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес не может быть пустым"])};fn.source="Адрес не может быть пустым";return fn;})(),
      "subdomain": {
        "required": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя не может быть пустым"])};fn.source="Доменное имя не может быть пустым";return fn;})(),
        "latin": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допускаются только латинские символы"])};fn.source="Допускаются только латинские символы";return fn;})(),
        "exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доменное имя уже существует"])};fn.source="Доменное имя уже существует";return fn;})()
      },
      "domain_exists": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт не сохранен. Такое доменное имя уже существует"])};fn.source="Контакт не сохранен. Такое доменное имя уже существует";return fn;})(),
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название не может быть пустым"])};fn.source="Название не может быть пустым";return fn;})(),
      "title_symbols": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле имеет ошибочный формат"])};fn.source="Поле имеет ошибочный формат";return fn;})(),
      "phone": {
        "empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона не может быть пустым"])};fn.source="Номер телефона не может быть пустым";return fn;})(),
        "length": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат телефона"])};fn.source="Неверный формат телефона";return fn;})(),
        "unique": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данный Телефон уже присутствует в данной точке продаж"])};fn.source="Данный Телефон уже присутствует в данной точке продаж";return fn;})(),
        "valid": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такой номер не валиден"])};fn.source="Такой номер не валиден";return fn;})()
      }
    },
    "catalog": {
      "point": {
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])};fn.source="Точка";return fn;})(),
        "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите точку продаж"])};fn.source="Выберите точку продаж";return fn;})()
      },
      "copy": {
        "label": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cкопировать каталог из предыдущей точки?"])};fn.source="Cкопировать каталог из предыдущей точки?";return fn;})(),
        "yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])};fn.source="Да";return fn;})(),
        "no": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])};fn.source="Нет";return fn;})()
      }
    }
  },
  "catalog": {
    "sale_channels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точки продаж"])};fn.source="Точки продаж";return fn;})(),
    "settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товары и услуги"])};fn.source="Товары и услуги";return fn;})(),
    "qr_catalog": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внешний вид меню"])};fn.source="Внешний вид меню";return fn;})(),
    "point": {
      "title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Точка"])};fn.source="Точка";return fn;})(),
      "placeholder": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите точку продаж"])};fn.source="Выберите точку продаж";return fn;})(),
      "not_selected": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом выберите точку"])};fn.source="Перед началом работы с каталогом выберите точку";return fn;})(),
      "list_is_empty": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед началом работы с каталогом создайте точку"])};fn.source="Перед началом работы с каталогом создайте точку";return fn;})()
    }
  },
  "products": {
    "errors": {
      "name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле не может быть пустым"])};fn.source="Поле не может быть пустым";return fn;})()
    }
  },
  "_": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([])};fn.source="";return fn;})(),
  "staff.errors.manLength": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поле должно содержать не более 25 символов."])};fn.source="Поле должно содержать не более 25 символов.";return fn;})()
}