import i18n from "@/i18n";
import { getStatusLoyalties } from "@/services/loyalty.service";
import store from "@/store";

export async function loyaltyProgramCheckMiddleware(to, from, next) {
  if (/\/.{2}\/login$/.test(to.path)) return next();
  // Get current progress
  const currentProgress = store.getters.get_progress;

  // If we're on the pselection step, allow navigation
  if (to.name === "pselection" && currentProgress === 2) {
    return next();
  }
  const activeLoyalty = await getStatusLoyalties();
  // console.log("middleware :activeLoyalty", activeLoyalty);

  // If on pselection and no active loyalties, allow navigation
  if (to.name === "pselection" && (!activeLoyalty || activeLoyalty.length === 0)) {
    return next();
  }

  // If on pselection with active loyalties, redirect to first loyalty
  if (to.name === "pselection" && activeLoyalty && activeLoyalty.length > 0) {
    return next({
      name: activeLoyalty.toString(),
      params: { locale: i18n.global.locale }
    });
  }
  return next();
}
